const formatter = new Intl.NumberFormat("en-US");
let customerCharges;

var agentFunc = function (agents) {
  if (agents !== null && agents.length > 0) {
    $(".agent-charge ").empty();
    // $(".agent-charge-dropdown").html(
    //   "<option  selected value> Please select</option>"
    // );
    for (var i = 0; i < agents.length; i++) {
      $(".agent-charge ").append(
        '<div class="flex items-center gap-2"><input type = "checkbox" name= "agents[]" class = "agent-checkbox" id="agents_" value = ' +
        agents[i]["id"] +
        ">" +
        '<b class= "text-lg">' +
        agents[i]["entity_registration"]["name"] +
        "</b></div>"
      );
      // $(".agent-charge-dropdown").append(
      //   '<option value="' +
      //   agents[i]["id"] +
      //   '">' +
      //   agents[i]['entity_registration']["name"] +
      //   "</option>"
      // );
    }
  } else {
    $(".agent-charge").empty();
    $(".agent-charge-dropdown").html(
      "<option  selected value>Please select</option>"
    );
    $(`.get-record-charge`).empty();
  }
};

var filterData = {};

var dropDownSetup = function (param, component, key) {
  component.empty().html("<option selected value>Please select</option>");

  if (param.length < 1) {
    delete filterData[key];
  } else {
    for (var i = 0; i < param.length; i++) {
      if (param[i]["entity_registration"] !== undefined) {
        component.append(
          '<option value="' +
          param[i]["id"] +
          '"' +
          (filterData[key] == param[i]["id"] ? "selected " : "") +
          ">" +
          param[i]["entity_registration"]["name"] +
          "</option>"
        );
      } else {
        component.append(
          '<option value="' +
          param[i]["id"] +
          '"' +
          (filterData[key] == param[i]["id"] ? "selected " : "") +
          ">" +
          (key == "product" ? param[i]["name"] + "(" + param[i]["currency_code"] + ")" : param[i]["name"]) +
          "</option>"
        );
      }
    }
  }
};

function setCustomerCharges(cc) {
  $(`.get-record-charge`).empty();
  if (cc.length > 0) {
    for (var i = 0; i < cc.length; i++) {
      $(`.get-record-charge`).append(
        `<tr id="charge-record-${cc[i].id}">` +
        `<td class="text-center">${formatter.format(
          cc[i].from_amount
        )}</td>` +
        `<td class="text-center">${formatter.format(cc[i].to_amount)}</td>` +
        `<td class="text-center">${formatter.format(cc[i].charge)}</td>` +
        '<td class="text-center"> <div class="flex justify-center flex-wrap gap-2">' +
        `<a class="btn btn-icon btn_outlined btn_secondary" data-remote="true" href="/customer_charges/${cc[i].id}/edit"><span class="la la-pen-fancy"></span></a>` +
        `<a class="btn btn-icon btn_outlined btn_danger" data-confirm="Are you sure?" data-remote="true" rel="nofollow" data-method="delete" href="/customer_charges/${cc[i].id}"><span class="la la-trash-alt"></span></a>` +
        "</div></td>" +
        "</tr>"
      );
    }
  }
}

function fetchData(data) {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: "/customer_charges/new",
      type: "GET",
      dataType: "json",
      data: filterData,
      error: function (xhr, status, error) {
        const e = "AJAX Error: " + status + error;
        console.error(e);
        reject(e);
      },
      success: function (response) {
        resolve(response);
        if (response.dropdown_agents && response.checkbox_agents) {
          agentFunc(response.checkbox_agents);
          dropDownSetup(
            response.dropdown_agents,
            $(".agent-charge-dropdown"),
            "customer_charges_filter"
          );
        }
        if (response.correspondents)
          dropDownSetup(
            response.correspondents,
            $(".correspondent-charge"),
            "correspondent"
          );
        if (response.products)
          dropDownSetup(response.products, $(".product-charge"), "product");
        if (response.customer_charges) {
          customerCharges = response.customer_charges;
          setCustomerCharges(customerCharges);
        }
      },
    });
  });
}

$(document).on("change", ".country-charge", function () {
  const country = $(this).val();
  filterData = { country: country };
  $("#agent_ids_hidden").val("");
  $("#cc_ids_hidden").val("");
  $(".destination-country-charge").val('');
  if (filterData.destination_country) delete filterData.destination_country
  fetchData(filterData).then(function () { });
});

$(document).on("change", ".destination-country-charge", function () {
  const destination_country = $(this).val();
  $("#agent_ids_hidden").val("");
  $("#cc_ids_hidden").val("");
  filterData.destination_country = destination_country;
  if (filterData.correspondent) delete filterData.correspondent;
  if (filterData.product) delete filterData.product;
  if (filterData.customer_charges_filter)
    delete filterData.customer_charges_filter;

  fetchData(filterData).then(function () { });
});

$(document).on("change", ".correspondent-charge", function () {
  const correspondent = $(this).val();
  $("#agent_ids_hidden").val("");
  $("#cc_ids_hidden").val("");
  filterData.correspondent = correspondent;
  if (filterData.product) delete filterData.product;
  if (filterData.customer_charges_filter)
    delete filterData.customer_charges_filter;

  fetchData(filterData).then(function () { });
});

$(document).on("change", ".product-charge", function () {
  const product = $(this).val();
  $("#agent_ids_hidden").val("");
  $("#cc_ids_hidden").val("");
  filterData.product = product;
  if (filterData.customer_charges_filter)
    delete filterData.customer_charges_filter;

  fetchData(filterData).then(function () { });
});

// filtering
$(document).on("change", ".agent-charge-dropdown", function () {
  const customer_charges_filter = $(this).val();
  $("#agent_ids_hidden").val("");
  $("#cc_ids_hidden").val("");
  filterData.customer_charges_filter = customer_charges_filter;
  fetchData(filterData).then(function () { });
});
// cancel btn for edit form
// $(document).ready(function () {
//   $(document).on("click", ".back-btn-charge", function () {
//     $(".nested_table").hide();
//   });
// });

var agentIds = [];
var customerChargeIds = [];
$(document).on("change", ".agent-checkbox", function () {
  const agentId = parseInt($(this).val(), 10);
  if ($(this).prop("checked")) {
    agentIds.push(agentId);
    customerCharges.forEach(function (cc) {
      customerChargeIds.push(cc["id"]);
    });
  } else {
    agentIds = agentIds.filter(function (id) {
      return id !== agentId;
    });
  }
  $("#agent_ids_hidden").val(JSON.stringify(agentIds));
  $("#cc_ids_hidden").val(JSON.stringify(customerChargeIds));
  const checkedCheckboxes = $(".agent-checkbox:checked").length;
  $("#update-btn").prop(
    "disabled",
    checkedCheckboxes == 0 || customerChargeIds.length === 0
  );
});
